<template>
  <div>
    <div>
      <a-form-model ref="searchForm" :model="searchForm" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row gutter="24">
          <a-col :span="8">
            <a-form-model-item label="查询日期">
              <a-range-picker allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="商品分类">
              <a-select allowClear>
                <a-select-option value="jack">分类</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button type="primary">查询</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <div style="text-align: center;">
      <a-row class="content" type="flex" align="middle" style="background-color: rgba(90, 139, 255, 0.1);">
        <a-col :span="4">
          <div style="color: rgb(90, 139, 255); font-size: 20px;">商品分布</div>
        </a-col>
        <a-col :span="4">
          <div>在架商品数</div>
          <div class="content-value">3</div>
          <div>较前 1 日 0%</div>
        </a-col>
        <a-col :span="4">
          <div>动销商品数</div>
          <div class="content-value">0</div>
          <div>较前 1 日 0%</div>
        </a-col>
      </a-row>
      <a-row class="content" type="flex" align="middle" style="background-color: rgba(47, 174, 68, 0.1);">
        <a-col :span="4">
          <div style="color: rgb(47, 174, 68); font-size: 20px;">商品访问</div>
        </a-col>
        <a-col :span="4">
          <div>被访问商品数</div>
          <div class="content-value">1</div>
          <div>较前 1 日 0%</div>
        </a-col>
        <a-col :span="4">
          <div>商品访客数</div>
          <div class="content-value">1</div>
          <div>较前 1 日 0%</div>
        </a-col>
        <a-col :span="4">
          <div>商品浏览量</div>
          <div class="content-value">1</div>
          <div>较前 1 日 0%</div>
        </a-col>
      </a-row>
      <a-row class="content" type="flex" align="middle" style="background-color: rgba(255, 175, 90, 0.1);">
        <a-col :span="4">
          <div style="color: rgb(255, 175, 90); font-size: 20px;">商品转化</div>
        </a-col>
        <a-col :span="4">
          <div>加购人数</div>
          <div class="content-value">1</div>
          <div>较前 1 日 0%</div>
        </a-col>
        <a-col :span="4">
          <div>加购件数</div>
          <div class="content-value">1</div>
          <div>较前 1 日 0%</div>
        </a-col>
        <a-col :span="4">
          <div>下单商品数</div>
          <div class="content-value">1</div>
          <div>较前 1 日 0%</div>
        </a-col>
        <a-col :span="4">
          <div>付款商品数</div>
          <div class="content-value">1</div>
          <div>较前 1 日 0%</div>
        </a-col>
        <a-col :span="4">
          <div>商品访问付款转化率</div>
          <div class="content-value">1</div>
          <div>较前 1 日 0%</div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OverviewPane',
    data() {
      return {
        searchForm: {

        },
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      };
    },
    methods: {
    },
  }
</script>

<style scoped>
  .content {
    margin-bottom: 4px;
    height: 110px;
  }

  .content-value {
    font-size: 30px;
    color: rgb(90, 139, 255);
  }
</style>